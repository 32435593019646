import { ControllerParams } from '@wix/yoshi-flow-editor';
import { encodeBase64Url, decodeBase64Url } from '../../../services/encode-base64';
import { CheckoutData, StatusData, SubPage } from '../../../types/common';
import {
  AppSectionParamsDecodeError,
  CheckoutDataDecodeError,
  StatusDataDecodeError,
  toError,
} from '../../../utils/errors';
import { extractIntegrationData, integrationDataToAppSectionParams } from '../../../utils/integrationData';
import { isDayful } from '../../../utils/is-dayful';

type PageSectionId = 'Checkout' | 'Thank You' | 'Paywall' | 'membership_plan_picker_tpa';

export const PageSectionIdMap: Record<SubPage['name'], PageSectionId> = {
  checkout: 'Checkout',
  status: 'Thank You',
  restricted: 'Paywall',
  list: 'membership_plan_picker_tpa',
};

export class Navigation {
  constructor(
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerParams['flowAPI'],
    protected appDefinitionId: string,
    protected readonly hasMultiplePages: boolean,
  ) {}

  get isMultiPage() {
    return this.hasMultiplePages;
  }

  async setCurrentPath(subPage: SubPage) {
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appDefinitionId,
      sectionId: this.isMultiPage ? PageSectionIdMap[subPage.name] : 'membership_plan_picker_tpa',
    });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + subPageToPath(subPage), { disableScrollToTop: true });
  }

  async getCurrentSubPage(): Promise<SubPage> {
    const { location } = this.wixCodeApi;
    const { path } = location;
    if (path[1] === 'payment') {
      try {
        return { name: 'checkout', checkoutData: decodeCheckoutData(path[2]) };
      } catch (e) {
        throw new CheckoutDataDecodeError(toError(e).message);
      }
    } else if (path[1] === 'status') {
      try {
        return {
          name: 'status',
          statusData: decodeStatusData(path[2]),
        };
      } catch (e) {
        throw new StatusDataDecodeError(toError(e).message);
      }
    } else {
      const params = parseAppSectionParams(location.query?.appSectionParams);
      const pageId = extractRestrictedPageId(params);
      const integrationData = extractIntegrationData(params);
      if (pageId) {
        return { name: 'restricted', pageId, integrationData };
      }
      return {
        name: 'list',
        integrationData: (await isDayful(this.flowAPI, this.wixCodeApi))
          ? { ...integrationData, navigateTo: location.baseUrl + '/_api/dayful/sch/route' }
          : integrationData,
      };
    }
  }
}

function decodeCheckoutData(encoded: string): CheckoutData {
  return decodeBase64Url(encoded);
}

function decodeStatusData(encoded: string): StatusData {
  return decodeBase64Url(encoded);
}

function subPageToPath(subPage: SubPage): string {
  switch (subPage.name) {
    case 'checkout':
      return '/payment/' + encodeBase64Url(subPage.checkoutData);
    case 'status':
      return '/status/' + encodeBase64Url(subPage.statusData);
    case 'restricted':
      return '/restricted';
    default:
    case 'list':
      return '?' + integrationDataToAppSectionParams(subPage.integrationData);
  }
}

function extractRestrictedPageId(appSectionParams: Record<string, string>) {
  return appSectionParams.restrictedPageId;
}

function parseAppSectionParams(value: string): Record<string, string> {
  try {
    return value ? JSON.parse(value) : {};
  } catch (e) {
    throw new AppSectionParamsDecodeError(toError(e).message);
  }
}
