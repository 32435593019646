import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { GroupsApi } from '../../../services';
import { WarmupData } from '../../../services/WarmupData';
import { CommonProps, IntegrationData, RestrictedProps } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { toError } from '../../../utils/errors';
import { Router } from './Router';

export class RestrictedController {
  constructor(
    protected setProps: (props: Partial<CommonProps & RestrictedProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected router: Router,
    protected groupsApi: GroupsApi,
    protected flowAPI: ControllerFlowAPI,
    protected warmupData: WarmupData,
  ) {}

  public async initialize(pageId: string, integrationData: IntegrationData) {
    return this.update(pageId, integrationData);
  }

  public async update(pageId: string, integrationData: IntegrationData) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.RestrictedPageLoaded);
    let missingPlanIds: string[] = [];
    try {
      missingPlanIds = await this.warmupData.cache('restricted.missingPlanIds', () =>
        this.groupsApi.getMissingPlanIds(pageId),
      );
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
    const missingPlan = missingPlanIds.length > 0;
    this.setProps({
      missingPlan,
      switchAccounts: this.logout,
      navigateToHomePage: () => this.router.gotoHomePage(),
      navigateToPlanList: () =>
        this.goToPlanList({
          ...integrationData,
          verticalStatusContent: {
            titleText: this.flowAPI.translations.t('restricted.thank-you.title'),
            buttonText: this.flowAPI.translations.t('restricted.thank-you.button'),
          },
          navigateToPageProps: pageId,
          restrictedPageId: undefined,
          planIds: missingPlan ? missingPlanIds : undefined,
        }),
    });
  }

  logout = () => this.wixCodeApi.user.logout();

  goToPlanList = (integrationData: IntegrationData) => this.router.gotoList(integrationData);
}
